import annotationGuide from './AnnotationGuide';
import workspaceGuide from './WorkspaceGuide';

function init() {
  this.annotationGuide.init();
}

function UserGuide() {
  const self = this;

  self.annotationGuide = annotationGuide;
  self.workspaceGuide = workspaceGuide;

  self.init = init.bind(self);
}

export default new UserGuide();
