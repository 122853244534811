import './bindings/index';
import 'bootstrap-autocomplete';
import { usePlacesAutocomplete } from 'commons/utils/geo';
import FindStudyViewModel from './FindStudyViewModel';
import bindSearchFilter from 'commons/utils/filter_list';
import browseLocation from './BrowseLocation';

(function ($, document, window) {
  $(function () {
    const $findStudy = document.getElementById('find-study-hero');
    const widgetId = window.__WIDGET__ || null;
    const $studyLocationInput = document.getElementById(
      'search-study-location',
    );

    const findStudyViewModel = new FindStudyViewModel({ widgetId });

    usePlacesAutocomplete($studyLocationInput, {
      onPlaceChange: findStudyViewModel.handleLocationChange,
    }).catch(console.log);

    $findStudy && ko.applyBindings(findStudyViewModel, $findStudy);

    browseLocation.init();
  });

  bindSearchFilter();
})($, document, window);
