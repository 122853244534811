import ClipboardJS from 'clipboard';
import { bindPopovers } from 'commons/bindings/popover';
import { navigateTo } from 'commons/utils/location';
import { functionalConsentChange$ } from 'commons/one_trust/consent';
import { ClientWorkspaceApi } from '../annotations/api';
import userGuide from '../user_guide';

const $workspaceContainer = $('#workspace-container');
const $workspacePopover = $('#workspace_popover');
const $workspaceToggle = $('.workspace-toggler');
const $saveTrialBtn = $('#save-trial');

const $workspaceAccessRoot = $('#workspace-access');
const $hideWorkspaceAccess = $workspaceAccessRoot.find('#hide-workspace');
const $workspaceAccessPasscode = $workspaceAccessRoot.find('#access-passcode');
const $workspaceAccessBtn = $workspaceAccessRoot.find('#access-btn');
const $workspaceAccessConsentHelpText = $workspaceAccessRoot.find(
  '#consent-help-text',
);

function showWorkspace() {
  $workspaceContainer.removeClass('d-none');
  $workspaceToggle.show();
}

function showWorkspaceAccess() {
  $workspaceAccessRoot.removeClass('d-none');
}

function hideWorkspace() {
  $workspaceContainer.addClass('d-none');
  $workspaceToggle.hide();
}

function showWorkspaceAccessConsent() {
  $workspaceAccessBtn.prop('disabled', true);
  $workspaceAccessConsentHelpText.removeClass('d-none');
}

function hideWorkspaceAccessConsent() {
  $workspaceAccessBtn.prop('disabled', false);
  $workspaceAccessConsentHelpText.addClass('d-none');
}

async function refreshWorkspace({
  success: successCb = null,
  error: errorCb = null,
} = {}) {
  try {
    if (!this.hasUserConsent && !this.isNavigatorRole) return;

    const { workspace } = await ClientWorkspaceApi.refreshWorkspace();

    $workspacePopover.html(workspace);
    bindPopovers();
    this.showWorkspace();

    successCb && successCb();

    userGuide.workspaceGuide.onWorkspaceCreated();
  } catch (error) {
    console.log(error.message);
    errorCb && errorCb(error);
  }
}

function setSavedTrials(savedTrials) {
  const $savedTrialsWrapper = $workspacePopover.find('#saved-trials');
  const hasSavedTrials = $savedTrialsWrapper.length === 1;

  hasSavedTrials
    ? $savedTrialsWrapper.replaceWith(savedTrials)
    : $workspacePopover.append(savedTrials);

  bindPopovers();
  this.showWorkspace();
}

function toggleSaveTrialBtn(saved = false) {
  const $savedIcon = $saveTrialBtn.find('#saved-icon');
  const $unsavedIcon = $saveTrialBtn.find('#unsaved-icon');
  const $savedLabel = $saveTrialBtn.find('#saved-trial-label');

  if (saved) {
    $savedIcon.removeClass('d-none');
    $unsavedIcon.addClass('d-none');
    $savedLabel.text('Unsave');
  } else {
    $savedIcon.addClass('d-none');
    $unsavedIcon.removeClass('d-none');
    $savedLabel.text('Save');
  }

  $saveTrialBtn.data('saved', saved);
}

async function toggleSavedTrial() {
  const saveTrial = !$saveTrialBtn.data('saved');
  const trialId = $saveTrialBtn.data('trial-id');
  try {
    this.toggleSaveTrialBtn(saveTrial);

    const { savedTrials } = await ClientWorkspaceApi.saveTrial({
      trialId,
      saveTrial,
    });
    await this.refreshWorkspace();
  } catch (error) {
    console.log(error);
    this.toggleSaveTrialBtn(!saveTrial);
  }
}

function getWorkspaceShareText({ shareLink, passcode }) {
  if (!shareLink || !passcode) return '';

  return `${shareLink}\n\nPasscode: ${passcode}`;
}

function setShareContent({ shareLink, passcode, $wrapper }) {
  const $workspaceShareContent = $($wrapper).find('.workspace-share-content');
  const $shareLink = $workspaceShareContent.find('.share-link');
  const $sharePasscode = $workspaceShareContent.find('.share-passcode');
  const $clipboardCopy = $workspaceShareContent.find('.copy-workspace-link');
  const workspaceShareText = this.getWorkspaceShareText({
    shareLink,
    passcode,
  });

  $shareLink.prop('href', shareLink);
  $shareLink.text(shareLink);
  $sharePasscode.text(passcode);
  $clipboardCopy.attr('data-clipboard-text', workspaceShareText);
  $clipboardCopy.text('Copy to clipboard');
}

function showWorkspaceShareHelper(e) {
  const $wrapper = $(e.currentTarget);
  const $workspaceShareHelper = $($wrapper).find('.workspace-share-helper');
  const $workspaceShareContent = $($wrapper).find('.workspace-share-content');

  $workspaceShareHelper.removeClass('d-none');
  $workspaceShareContent.addClass('d-none');
}

function showWorkspaceShareContent($wrapper) {
  const $workspaceShareHelper = $($wrapper).find('.workspace-share-helper');
  const $workspaceShareContent = $($wrapper).find('.workspace-share-content');

  $workspaceShareHelper.addClass('d-none');
  $workspaceShareContent.removeClass('d-none');

  this.initClipboardCopy($wrapper);
}

async function generateWorkspaceShareLink(e) {
  const $generateShareLinkBtn = $('.generate-workspace-link');
  const $wrapper = e.currentTarget.closest('.generate-session-detail');
  try {
    $generateShareLinkBtn.prop('disabled', true);

    const {
      shareLink,
      passcode,
    } = await ClientWorkspaceApi.generateShareLink();

    this.setShareContent({ shareLink, passcode, $wrapper });
    this.showWorkspaceShareContent($wrapper);
  } catch (error) {
    console.log(error);
  } finally {
    $generateShareLinkBtn.prop('disabled', false);
  }
}

function initClipboardCopy($wrapper) {
  const $clipboardCopyBtn = $wrapper.querySelector('.copy-workspace-link');

  this.clipboard && this.clipboard.destroy();

  this.clipboard = new ClipboardJS($clipboardCopyBtn);
  this.clipboard.on('success', (_) => {
    $($clipboardCopyBtn).text('Copied!');
  });

  this.clipboard.on('error', (e) => {
    console.log('Error occurred while coping text to clipboard!', e);
  });
}

async function clearWorkspaceActivity(e) {
  try {
    e.preventDefault();

    await ClientWorkspaceApi.clearWorkspaceActivity();
    location.reload();
  } catch (error) {
    console.log(error);
  }
}

async function accessWorkspace() {
  const $passcodeError = $workspaceAccessRoot.find('#access-passcode-error');

  function showError(error) {
    $passcodeError.text(error);

    $workspaceAccessPasscode.parent().addClass('is-invalid');
    $passcodeError.removeClass('d-none');
  }

  function hideError() {
    $workspaceAccessPasscode.parent().removeClass('is-invalid');
    $passcodeError.addClass('d-none');
  }

  try {
    const workspaceUrlCode = this.workspaceUrlCode;
    const passcode = $workspaceAccessPasscode.val();
    if (!passcode) {
      $workspaceAccessPasscode.focus();
      return;
    }

    hideError();
    $workspaceAccessBtn.prop('disabled', true);
    const { redirectUrl } = await ClientWorkspaceApi.accessWorkspace({
      passcode,
      workspaceUrlCode,
    });

    navigateTo(redirectUrl);
  } catch (error) {
    console.log(error.message);
    showError(error.message);
  } finally {
    $workspaceAccessBtn.prop('disabled', false);
  }
}

function hideSaveTrial() {
  $saveTrialBtn.addClass('d-none');
}

function showSaveTrial() {
  $saveTrialBtn.removeClass('d-none');
}

function bindWorkspaceAccess() {
  $hideWorkspaceAccess.click(function () {
    $workspaceAccessRoot.addClass('d-none');
  });

  $workspaceAccessBtn.click(this.accessWorkspace);
}

function bindWorkspaceShare() {
  const generateWorkspaceShare = '.generate-workspace-share';
  const generateShareLinkBtn = '.generate-workspace-link';

  $(document.body).on('click', generateWorkspaceShare, function (e) {
    e.preventDefault();
    const sessionTarget = $(e.currentTarget).attr('data-target');
    const $shareWorkspaceContent = $(sessionTarget);
    $shareWorkspaceContent.collapse('toggle');
  });

  $(document.body).on(
    'click',
    generateShareLinkBtn,
    this.generateWorkspaceShareLink,
  );

  $(document.body).on(
    'show.bs.collapse',
    '#generate-session, #generate-session-mobile',
    this.showWorkspaceShareHelper,
  );
}

function bindSaveSite() {
  $saveTrialBtn.click(this.toggleSavedTrial);
}

function bindEvents() {
  $(document.body).on('click', '.clear-workspace', this.clearWorkspaceActivity);

  functionalConsentChange$.subscribe(this.handleConsentChange);
}

function handleConsentChange(enabled) {
  this.hasUserConsent = enabled;

  if (enabled) {
    this.showSaveTrial();
    this.refreshWorkspace();
    this.hideWorkspaceAccessConsent();
  } else {
    this.hideSaveTrial();
    !this.isNavigatorRole && this.hideWorkspace();
    this.showWorkspaceAccessConsent();
  }

  this.showWorkspaceAccess();
}

function init({ workspaceUrlCode = null, isNavigatorRole = false }) {
  this.workspaceUrlCode = workspaceUrlCode;
  this.isNavigatorRole = isNavigatorRole;

  this.bindEvents();
  this.bindSaveSite();
  this.bindWorkspaceAccess();
  this.bindWorkspaceShare();

  this.isNavigatorRole && this.refreshWorkspace();
}

function ClientWorkspace() {
  const self = this;

  self.clipboard = null;
  self.workspaceUrlCode = null;
  self.isNavigatorRole = false;
  self.hasUserConsent = false;

  self.init = init.bind(self);
  self.bindEvents = bindEvents.bind(self);
  self.bindSaveSite = bindSaveSite.bind(self);
  self.bindWorkspaceShare = bindWorkspaceShare.bind(self);
  self.bindWorkspaceAccess = bindWorkspaceAccess.bind(self);
  self.refreshWorkspace = refreshWorkspace.bind(self);
  self.toggleSavedTrial = toggleSavedTrial.bind(self);
  self.setSavedTrials = setSavedTrials.bind(self);
  self.showWorkspace = showWorkspace.bind(self);
  self.hideWorkspace = hideWorkspace.bind(self);
  self.toggleSaveTrialBtn = toggleSaveTrialBtn.bind(self);
  self.generateWorkspaceShareLink = generateWorkspaceShareLink.bind(self);
  self.setShareContent = setShareContent.bind(self);
  self.showWorkspaceShareContent = showWorkspaceShareContent.bind(self);
  self.showWorkspaceShareHelper = showWorkspaceShareHelper.bind(self);
  self.getWorkspaceShareText = getWorkspaceShareText.bind(self);
  self.initClipboardCopy = initClipboardCopy.bind(self);
  self.clearWorkspaceActivity = clearWorkspaceActivity.bind(self);
  self.accessWorkspace = accessWorkspace.bind(self);
  self.handleConsentChange = handleConsentChange.bind(self);
  self.showSaveTrial = showSaveTrial.bind(self);
  self.hideSaveTrial = hideSaveTrial.bind(self);
  self.showWorkspaceAccess = showWorkspaceAccess.bind(self);
  self.showWorkspaceAccessConsent = showWorkspaceAccessConsent.bind(self);
  self.hideWorkspaceAccessConsent = hideWorkspaceAccessConsent.bind(self);
}

export default new ClientWorkspace();
