import getValue from 'lodash/get';
/**
 * @function bindSearchFilter
 * Utility method to filter list of item on client. Structure should be as follows:
 *
 * e.g
 *  <input type="text" class="search-filter-input" data-filter-list="result-list-id" />
 *
 * <!-- And in the result list --!>
 * <ul id="result-list-id">
 *      <li><li>
 *      <li></li>
 *      ...
 * </ul>
 *
 * This input should have filter-list data attribute that contains the Id of the list to be
 * filtered.
 * Input selector can be be custom or default to "".search-filter-input".
 *
 * @param {string} [inputSelector='.search-filter-input'] search filter input class or id selector
 */
export default function bindSearchFilter(
  inputSelector = '.search-filter-input',
) {
  const $filterInputs = $(inputSelector);

  $filterInputs.each(function () {
    const $filterInput = $(this);
    const filterListId = $filterInput.data('filter-list');
    const $resultList = $(`#${filterListId}`);

    $resultList.addClass('filter_list');

    $filterInput.on('keyup', function (e) {
      const filterText = getValue(e, 'target.value', '').toLowerCase();

      const $filterList = $resultList.find('li');
      $filterList.each(function () {
        const $item = $(this);
        const itemText = $item.text().toLowerCase();

        itemText.includes(filterText)
          ? $item.removeClass('d-none')
          : $item.addClass('d-none');
      });

      const $hiddenItems = $resultList.find('.d-none');
      const $noResult = $resultList.find('.no-results');

      if ($hiddenItems.length === $filterList.length) {
        !$noResult.length &&
          $resultList.append(
            '<span class="text-muted no-results">No results found!</span>',
          );
      } else {
        $noResult.remove();
      }
    });
  });
}
