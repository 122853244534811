import debounce from 'lodash/debounce';

export default function HeaderViewModal() {
  const self = this;
  self.workspaceIsVisible = ko.observable(true);
  self.onWorkspaceShow = function () {
    self.workspaceIsVisible(true);
  };
  self.onWorkspaceHide = function () {
    self.workspaceIsVisible(false);
  };
  self.toggleWorkspace = function() {
    self.workspaceIsVisible(!self.workspaceIsVisible())
  }
  $(document).scroll(
    debounce(function () {
      const doc = this;
      if ($(doc).scrollTop() > 60) {
        self.workspaceIsVisible() && self.onWorkspaceHide();
      } else {
        !self.workspaceIsVisible() && self.onWorkspaceShow();
      }
    }, 100)
  );
}
