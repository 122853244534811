import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './commons/polyfills/';
import 'rx-jquery';
import 'popper.js'; //we will use popper2.0 custom
import 'bootstrap';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import './commons/knockout';
import clientWorkspace from '../../clinicaltrial_view/src_ui/js/workspace';
import HeaderViewModel from './commons/view_models/header';
import '../node_modules/owl.carousel/dist/owl.carousel.min.js';
(function ($, document, window) {

  function centerWatch() {
    function bindScrollTop() {
      const $scrollTop = $('.scroll-top-fab');
      const $scrollTopBtn = $('#scroll-top-btn');

      if (!$scrollTop || !$scrollTopBtn) return;

      fromEvent(window, 'scroll')
        .pipe(debounceTime(500))
        .subscribe((_) => {
          const scrollOffset = window.scrollY;

          scrollOffset > 300 ? $scrollTop.fadeIn(300) : $scrollTop.fadeOut(300);
        });

      fromEvent($scrollTopBtn, 'click').subscribe((_) => {
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          700,
        );
      });
    }

    const workspaceUrlCode = window.__WORKSPACE_URL_CODE__;
    const isNavigatorRole = window.__IS_NAVIGATOR__;
    const headerViewModel = new HeaderViewModel();
    const $header = document.getElementById('header-vm');

    clientWorkspace.init({ workspaceUrlCode, isNavigatorRole });

    $header && ko.applyBindings(headerViewModel, $header);
    bindScrollTop();
  }

  function takeoutEmbed() {
    const countryCode = window.__COUNTRY_CODE__;
    const languageCode = window.__LANGUAGE_CODE__;

    const $countryLanguageDropdowns = $('#country-dropdown, #language-dropdown');
    const $countryDropdown = $('#country-dropdown');
    const $languageDropdown = $('#language-dropdown');

    // Init bootstrap-select
    $countryLanguageDropdowns.selectpicker({
      style: "select-custom-btn",
    });
    $countryLanguageDropdowns.parent().addClass('select-custom');

    $countryDropdown.selectpicker('val', countryCode);
    $languageDropdown.selectpicker('val', languageCode);
    const isTextClamped = elm => elm.scrollHeight > elm.clientHeight
    var ele = document.getElementsByClassName('browse-inner-wrapper-div');
    var item
    for (var i = 0; i < ele.length; ++i) {
        item= ele[i]
      var headingEl= ele[i].querySelector('.browse-condition-heading')
        if (!isTextClamped(headingEl)) {
        item.removeAttribute('title');
        item.removeAttribute('data-original-title');
        }
}


  //
//

//
    $('.owl-carousel').owlCarousel({
      items: 3,
      loop: true,
      nav: true,
      dots: true,
      smartSpeed: 400,
      autoheight: true,
      autoplay: true,
      margin: 20,
      navText: [
          "<span><svg xmlns='http://www.w3.org/2000/svg' width='14.006' height='42.846' viewBox='0 0 14.006 42.846'><path id='Path_3858' data-name='Path 3858' d='M-15820.121,4967.409l-11.48,19.1,11.48,21.02' transform='translate(15832.754 -4966.037)' fill='none' stroke='#fff' stroke-linecap='round' stroke-width='2'/></svg></span>",
          "<span><svg xmlns='http://www.w3.org/2000/svg' width='14.006' height='42.846' viewBox='0 0 14.006 42.846'><path id='Path_3859' data-name='Path 3859' d='M-15831.6,4967.409l11.48,19.1-11.48,21.02' transform='translate(15832.975 -4966.037)' fill='none' stroke='#fff' stroke-linecap='round' stroke-width='2'/> </svg></span>"
         ],
      responsive: {
        0: {
          items: 1.3,

        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });
    $('.can-animate').each(function(){
      $($(this)).addClass('hide-block');
      if (isScrolledIntoView($($(this)))) {
        $($(this)).removeClass('hide-block');
        $($(this)).addClass('animate__fadeInUp animate__animated');
      }
    })
    // $('.can-animate-right').each(function(){
    //   if (isScrolledIntoView($($(this)))) {
    //     $($(this)).removeClass('hide-block');
    //     $($(this)).addClass('animate__fadeInRightBig animate__animated');
    //   }
    // })
    
    // $('.can-animate-left').each(function(){
    //   if (isScrolledIntoView($($(this)))) {
    //     $($(this)).removeClass('hide-block');
    //     $($(this)).addClass('animate__fadeInLeftBig animate__animated');
    //   }
    // })
    function reduceNavbarHeight() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("logo").classList.add("receeded-height");
      } else {
        document.getElementById("logo").classList.remove("receeded-height");
      }
    }
  
    $(window).on('scroll', function(event) {
      $('.can-animate').each(function(){
        if (isScrolledIntoView($($(this)))) {
        $($(this)).removeClass('hide-block');
        $($(this)).addClass('animate__fadeInUp animate__animated');
        }
      })
      if(document.getElementById("logo")){
        reduceNavbarHeight()
      }
      // $('.can-animate-right').each(function(){
      //   if (isScrolledIntoView($($(this)))) {
      //   $($(this)).removeClass('hide-block');
      //   $($(this)).addClass('animate__fadeInRightBig animate__animated');
      //   }
      // })
      // $('.can-animate-left').each(function(){
      //   if (isScrolledIntoView($($(this)))) {
      //   $($(this)).removeClass('hide-block');
      //   $($(this)).addClass('animate__fadeInLeftBig animate__animated');
      //   }
      // })
      });
      //slide animation
      $(".browse-nav-link").click(function() {
        var position = $(this).parent().position();
        var width = $(this).parent().width();
          $(".browse-slider").css({"left":+ position?.left,"width":width,"top":position?.top});
      });
      var actWidth = $("#browse .nav-tabs").find(".active").parent("li").width();
      var actPosition = $("#browse .nav-tabs .active").position();
      $(".browse-slider").css({"left":+ actPosition?.left,"width": actWidth,"top":actPosition?.top});
  }

  function isScrolledIntoView(elem)
  {
    var top_of_element = $(elem).offset().top + 100;
    var bottom_of_element = top_of_element + $(elem).outerHeight();
    var top_of_screen = $(window).scrollTop();
    var bottom_of_screen = top_of_screen + $(window).height();

    return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)
  };



  $(function () {
    process.env.CENTER_WATCH_APP_TYPE ? takeoutEmbed() : centerWatch();
  });
})($, document, window);
