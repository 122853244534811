import { map } from 'rxjs/operators';
import { listen } from 'commons/observable';

export const FUNCTIONAL_COOKIES_GROUP = 'C0003';

export const consentChange$ = listen(document.body, 'ot.consent.change');

export const functionalConsentChange$ = consentChange$.pipe(
  map((e) => e.detail),
  map(
    (cookieGroups) =>
      Array.isArray(cookieGroups) &&
      cookieGroups.includes(FUNCTIONAL_COOKIES_GROUP)
  )
);
