/**
 * Ponyfills [1] for DOM element methods that are not supported in all browsers.
 *
 * [1] https://github.com/sindresorhus/ponyfill
 */

import { isBrowserIE11 } from './browser';

/**
 * Polyfill for Node.contains() to support text node element on IE-11.
 * For text node elements, Node.contains() always returns false on IE-11.
 *
 * @param {HTMLElement} root - root container element to check against
 * @param {Node} element - Node elements to be contained within container
 */
export function containsNode(root, element) {
  let node = element;

  if (!isBrowserIE11()) {
    return root.contains(element);
  }

  while (node !== null && node !== root.parentNode) {
    if (node === root) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

/**
 * Utility function to calculate total offset of the element using viewport offset
 * and scroll offset of window.
 * @param {DOMRect} targetRect using Element.getBoundingClientRect()
 *
 * @returns null or top/left/right/bottom offset position of the element.
 */
export function getBoundingOffset(targetRect) {
  if (!targetRect) {
    return null;
  }

  const { pageXOffset, pageYOffset } = window;
  const { top, right, bottom, left } = targetRect;

  return {
    top: top + pageYOffset,
    left: left + pageXOffset,
    bottom: bottom + pageYOffset,
    right: right + pageXOffset,
  };
}

/**
 * Implementation of `element.closest(selector)`. This is used to support browsers
 * (IE 11) that don't have a native implementation.
 */
export function closest(element, selector) {
  while (element) {
    if (element.matches(selector)) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
}

/**
 * Utility function to get the viewport dimensions
 *
 * @return { viewportWidth: number, viewportHeight: number } dimensions of viewport
 */
export function getViewportDimensions() {
  const viewportWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  const viewportHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );

  return {
    viewportWidth,
    viewportHeight,
  };
}

export default {
  containsNode,
  getBoundingOffset,
  closest,
  getViewportDimensions,
};
