const slideIn = {
  init: function (element, valueAccessor) {
    var value = valueAccessor();
    $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
  },
  update: function (element, valueAccessor) {
    var value = valueAccessor();
    if (ko.unwrap(value)) {
      $(element).css({ position: "fixed", top: "4rem" });
    } else {
      $(element).animate({ top: "0rem" }, "fast");
    }
  },
};
ko.bindingHandlers.slideIn = slideIn
