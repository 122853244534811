import URI from 'urijs';
import getValue from 'lodash/get';
import { updateLocationHistory } from './location';

/**
 * Utility method for setting query params in the current url
 *
 * @param {object} params contain the query params mapped by the keys
 * @param {object} options - config containing the options
 * @param {push|pop|replace} options.action - for changing the browser location stack
 */
export function setQueryParams(params = {}, { action = 'push' } = {}) {
  const uri = new URI();
  const removedParams = [];

  Object.entries(params).forEach(([param, values]) => {
    if (!values || (Array.isArray(values) && values.length === 0)) {
      removedParams.push(param);
      delete params[param];
    }
  });

  uri.setSearch(params);
  uri.removeQuery(removedParams);

  const pathname = uri.pathname();
  const search = uri.search();

  updateLocationHistory({ pathname, search }, action);
}

/**
 * Utility method for the getting the query params from the current url
 *
 * @return object containing the params mapped with their respective keys.
 */
export function getQueryParams() {
  const uri = new URI();

  return uri.query(true);
}

/**
 * Utility method for getting query params by specified key(s).
 *
 * @param {array|string} keys mapped with the query params in the URL
 *
 * @returns {object} contains the key mapped with the params
 */
export function getQueryParamsByKeys(keys) {
  if (!Array.isArray(keys)) keys = [keys];

  const params = getQueryParams();
  const result = {};
  keys.forEach((key) => {
    result[key] = getValue(params, key, null);
  });

  return result;
}

/**
 * Utility method for removing the query params from the URL based on the param key
 *
 * @param {array} paramNames - list of query param keys
 * @param {object} options - options for config
 * @param {push|pop|replace} [options.action = 'push'] - action while updating the location stack
 */
export function removeQueryParams(paramNames = [], { action = 'push' } = {}) {
  if (!Array.isArray(paramNames)) paramNames = [paramNames];

  const uri = new URI();

  uri.removeQuery(paramNames);
  const search = uri.search();

  updateLocationHistory({ search }, action);
}

/**
 * Utility method for removing the query params from the URL based on the param key and respective value
 *
 * @param {array} paramNames - list of query param keys
 * @param {object} options - options for config
 * @param {push|pop|replace} [options.action = 'push'] - action while updating the location stack
 */
export function removeQueryParamWithValues(
  paramNames = {},
  { action = 'push' } = {},
) {
  const uri = new URI();

  uri.removeQuery(paramNames);
  const search = uri.search();

  updateLocationHistory({ search }, action);
}

/**
 * Utility method for getting the URL pathname, query params and hash code.
 *
 * @returns {string} containing the resulting url
 */
export function getURLResource() {
  return new URI().resource();
}

/**
 * @function getURLParts
 * Utility method to get various parts of URL
 *
 * @returns {object} containing various parts of URL.
 */
export function getURLParts() {
  const uri = new URI();

  const scheme = uri.scheme();
  const subdomain = uri.subdomain();
  const domain = uri.domain();
  const path = uri.path();

  return {
    scheme,
    subdomain,
    domain,
    path,
  };
}

export function hasQueryParam(key) {
  const uri = new URI();

  return uri.hasQuery(key);
}
