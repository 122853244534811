import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import history from 'history/browser';
var hostSite = process.env.CENTER_WATCH_TAKEOUT_HOSTNAME
function sendMessage(data){
    if(window.parent){
          window.parent.postMessage(data,hostSite);
    }
}

function calculateHeight(){
  let $body = document.getElementsByTagName("body")
  sendMessage({height:$body[0].scrollHeight})

}


(function () {
    
    let location = history.location;
    sendMessage({location:location})
    calculateHeight();
    window.addEventListener("message", (event) => {
      if (event.data.getHeight){
          calculateHeight();
      }
    });

    $('.collapse').on('shown.bs.collapse', function () {
       calculateHeight();
     })

     $('.collapse').on('hidden.bs.collapse', function () {
        calculateHeight();
    })
})()

history.listen(function(location) {
  sendMessage({location:location})
})

