import 'fast-text-encoding';
import './string.prototype.normalize';
import customEvent from './customEvent';
import documentEvaluate from './document.evaluate';
import elementMatches from './element.matches';
import runStickyfills from './stickyfills'

(function () {
  customEvent();
  documentEvaluate();
  elementMatches();
  runStickyfills();
})();
