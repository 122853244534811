import $ from "jquery";
import { bindPopovers } from "./popover";
import selectpicker from "bootstrap-select";

$(".input-group-custom .form-control").on("focus", function (e) {
  $(this).closest(".input-group-custom").addClass("focused");
});
$(".input-group-custom .form-control").on("blur", function (e) {
  $(this).closest(".input-group-custom").removeClass("focused");
});
$('.custom-chip input[type="text"]').on("focus", function (e) {
  $(this).closest(".input-chip").addClass("focussed-input-chip");
});
$('.custom-chip input[type="text"]').on("blur", function (e) {
  $(this).closest(".input-chip").removeClass("focussed-input-chip");
});

$(".input-group-custom .form-control:disabled")
  .closest(".input-group-custom")
  .addClass("disabled");
$(".select-group .form-control:disabled")
  .closest(".select-group")
  .addClass("disabled");
$(function () {
  $(".select-custom").selectpicker({
    style: "select-custom-btn",
  });
});

function hideTooltips() {
  $("[data-toggle=tooltip]").tooltip("hide");
}
$(function () {
  bindPopovers();
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  $("[data-toggle=tooltip]").tooltip({ boundary: "window" });
  $("[data-toggle=tooltip][data-trigger=click]").on(
    "shown.bs.tooltip",
    function () {
      $("body").bind("click", hideTooltips);
    }
  );
  $("[data-toggle=tooltip][data-trigger=click]").on(
    "hidden.bs.tooltip",
    function () {
      $("body").unbind("click", hideTooltips);
    }
  );
});
