import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const Api = {};

function validateStatusDefault(status) {
  return status >= 200 && status < 300;
}

Api.get = function (url, { params } = {}) {
  return axios.get(url, { params });
};

Api.post = function (
  url,
  data,
  { validateStatus = validateStatusDefault, headers } = {},
) {
  return axios.post(url, data, { validateStatus, headers });
};

Api.put = function (url, data, {} = {}) {
  return axios.put(url, data);
};

Api.delete = function (url, {} = {}) {
  return axios.delete(url);
};

export default Api;
