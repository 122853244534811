import loadGoogleMapsApi from 'load-google-maps-api';
import getValue from 'lodash/get';
import { GOOGLE_MAPS_API_KEY } from 'commons/config/constants';

/**
 * Utility for setting up google places autocomplete on the given
 * input element.
 *
 * @param {HTMLInputElement} $element - input element for autocomplete search box.
 *
 * @returns Promise containing the autocomplete element.
 */
export function usePlacesAutocomplete($element, { onPlaceChange = null } = {}) {
  return loadGoogleMapsApi({
    key: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    timeout: 40000,
    language: $element ? $element.dataset.currentlanguage ? $element.dataset.currentlanguage : 'en' : 'en'

  }).then((googleApis) => {
    if ($element){
      const autocomplete = new googleApis.places.Autocomplete($element, { types: ['(regions)']});

      autocomplete.addListener(
        'place_changed',
        () => typeof onPlaceChange === 'function' && onPlaceChange(autocomplete),
      );

      return autocomplete;
    }else{
      return null
    }
  });
}

export function getGooglePlaceDetails(place) {
  function getAddressComponentValue(addressComponents = [], addressComponent) {
    const addressComponentList = addressComponents
      .filter(({ types = [] }) => getValue(types, '[0]', null) === addressComponent)
      .map(({ long_name }) => long_name);
    return getValue(addressComponentList, [0], null);
  }

  if (!place) return null;

  const locationName = getValue(place, 'formatted_address', null);
  const getLatitude = getValue(place, 'geometry.location.lat', null);
  const getLongitude = getValue(place, 'geometry.location.lng', null);
  const addressComponents = getValue(place, 'address_components', []);
  const countryName = getAddressComponentValue(addressComponents, 'country');
  const cityName = getAddressComponentValue(addressComponents, 'locality');
  const stateName = getAddressComponentValue(addressComponents, 'administrative_area_level_1');
  const postalCode = getAddressComponentValue(addressComponents, 'postal_code');

  return {
    locationName,
    countryName,
    latitude: getLatitude && getLatitude(),
    longitude: getLongitude && getLongitude(),
    cityName,
    stateName,
    postalCode,
  };
}
