import getValue from 'lodash/get';
import Api from 'api';
import {
  normalizeAnnotations,
  normalizeAnnotationResponses,
} from './utils/normalize';

export class AnnotationApi {
  static addNewAnnotation({
    trialId,
    quote,
    comment,
    selectors,
    sectionType,
    isPublicAnnotation = false,
  }) {
    if (!trialId) {
      throw new TypeError('Trial ID cannot be null', trialId);
    }

    const url = `/workspace/clinicaltrial/${trialId}/annotation/${
      isPublicAnnotation ? 'public' : 'private'
    }/`;

    const data = new FormData();
    data.append('quote', quote);
    data.append('comment', comment);
    data.append('selectors', JSON.stringify(selectors));
    data.append('section_type', sectionType);

    return Api.post(url, data).then(({ status, data }) => {
      if (status === 201) {
        const { count, message, result } = data;

        const annotation = getValue(
          normalizeAnnotations(result, { isPublicAnnotation }),
          '[0]',
          null
        );

        return { count, message, annotation };
      }
      return Promise.reject(
        new Error(data.message || 'Error while adding new annotation')
      );
    });
  }

  static fetchPrivateAnnotations({
    trialId,
    isSharedAnnotations,
    shareUrlCode,
  }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/private/`;

    const params = {};

    if (isSharedAnnotations) params.url_code = shareUrlCode;

    return Api.get(url, { params }).then(({ status, data }) => {
      if (status === 200) {
        const { result, count, message } = data;
        const annotations = normalizeAnnotations(result);

        return { count, message, annotations };
      }

      return Promise.reject(
        new Error(data.message || 'Error while listing the annotations')
      );
    });
  }

  static updateAnnotation({
    trialId,
    annotationId,
    comment,
    isPublicAnnotation = false,
  }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/${
      isPublicAnnotation ? 'public' : 'private'
    }/${annotationId}/`;

    const data = new FormData();
    data.append('comment', comment);

    return Api.put(url, data).then(({ status, data }) => {
      if (status === 200) {
        const { count, result, message } = data;

        const annotation = getValue(
          normalizeAnnotations(result, { isPublicAnnotation }),
          '[0]',
          null
        );

        return { annotation, count, message };
      }

      return Promise.reject(
        new Error(data.message || 'Error while deleting the annotation')
      );
    });
  }

  static deleteAnnotation({
    trialId,
    annotationId,
    isPublicAnnotation = false,
  }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/${
      isPublicAnnotation ? 'public' : 'private'
    }/${annotationId}/`;

    return Api.delete(url).then(({ status, data }) => {
      if (status === 200) {
        const { message } = data;
        return Promise.resolve({ message });
      }

      return Promise.reject(
        new Error(data.message || 'Error while deleting the annotation')
      );
    });
  }

  static generateShareLink({ trialId }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/share/`;

    return Api.post(url).then(({ status, data }) => {
      if (status === 201) {
        const {
          share_link: shareLink,
          passcode,
          expires_after: expiresAfter,
        } = data;

        return Promise.resolve({ shareLink, passcode, expiresAfter });
      }

      return Promise.reject(
        new Error(
          data.message,
          'Error while generating share link for annotation'
        )
      );
    });
  }

  static verifyPasscode({ shareUrlCode, passcode }) {
    const url = `/workspace/annotation/validate_passcode/`;

    const data = new FormData();
    data.append('url_code', shareUrlCode);
    data.append('passcode', passcode);

    return Api.post(url, data, { validateStatus: () => true }).then(
      ({ status, data }) => {
        const { message } = data;

        if (status === 200) {
          return Promise.resolve({ message });
        }

        return Promise.reject(new Error(message));
      }
    );
  }

  static replyAnnotation({ trialId, reply, annotationId, nickname }) {
    if (!trialId) {
      throw new TypeError('Trial ID cannot be null', trialId);
    }

    const url = `/workspace/clinicaltrial/${trialId}/annotation/private/`;

    const data = new FormData();
    data.append('comment', reply);
    data.append('parent', annotationId);
    data.append('nickname', nickname);

    return Api.post(url, data).then(({ status, data }) => {
      if (status === 201) {
        const { count, message, result } = data;

        const response = getValue(
          normalizeAnnotationResponses(result),
          '[0]',
          null
        );

        return { response };
      }
      return Promise.reject(
        new Error(data.message || 'Error while replying annotation')
      );
    });
  }

  static deleteAnnotationReply({ trialId, replyId }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/private/${replyId}/`;

    return Api.delete(url).then(({ status, data }) => {
      if (status === 200) {
        const { message } = data;
        return Promise.resolve({ message });
      }

      return Promise.reject(
        new Error(data.message || 'Error while deleting the reply')
      );
    });
  }

  static updateAnnotationReply({ trialId, replyId, replyText }) {
    const url = `/workspace/clinicaltrial/${trialId}/annotation/private/${replyId}/`;

    const data = new FormData();
    data.append('comment', replyText);

    return Api.put(url, data).then(({ status, data }) => {
      if (status === 200) {
        const { count, message, result } = data;

        const response = getValue(
          normalizeAnnotationResponses(result),
          '[0]',
          null
        );

        return { response };
      }
      return Promise.reject(
        new Error(data.message || 'Error while replying annotation')
      );
    });
  }
}

export class ClientWorkspaceApi {
  static refreshWorkspace() {
    const url = '/workspace/workspace_bar/';

    return Api.get(url).then(({ status, data: workspace }) => {
      if (status === 200) {
        return Promise.resolve({ workspace });
      }

      return Promise.reject(new Error('Error while refreshing the workspace'));
    });
  }

  static saveTrial({ trialId, saveTrial = false }) {
    const url = `/workspace/clinicaltrial/${trialId}/save/`;
    const action = saveTrial ? 'save' : 'unsave';
    const data = new FormData();

    data.append('action', action);

    return Api.post(url, data).then(({ status, data: savedTrials }) => {
      if (status === 200) {
        return Promise.resolve({ savedTrials });
      }

      return Promise.reject(new Error('Error while saving trial'));
    });
  }

  static generateShareLink() {
    const url = '/workspace/share/';

    return Api.post(url).then(({ status, data }) => {
      if (status === 201) {
        const {
          share_link: shareLink,
          passcode,
          expires_after: expireAfter,
        } = data;

        return Promise.resolve({ shareLink, passcode, expireAfter });
      }

      return Promise.reject(
        new Error('Error while generating workspace share link')
      );
    });
  }

  static accessWorkspace({ workspaceUrlCode, passcode }) {
    const url = '/workspace/validate_passcode/';

    const data = new FormData();
    data.append('url_code', workspaceUrlCode);
    data.append('passcode', passcode);

    return Api.post(url, data, { validateStatus: () => true }).then(
      ({ status, data }) => {
        if (status === 200) {
          const { message, redirect_url: redirectUrl } = data;
          return Promise.resolve({ message, redirectUrl });
        }

        return Promise.reject(
          new Error(data.message || 'Something went wrong.')
        );
      }
    );
  }

  static clearWorkspaceActivity() {
    const url = '/workspace/clear_activity/';

    return Api.post(url).then(({ status, message }) => {
      return status === 200
        ? Promise.resolve({ message })
        : Promise.reject(new Error(message));
    });
  }
}
export class EligibilityApi {
  static checkEligibility(formData, trialId) {
    const url = `/prescreen/prescreener_light/${trialId}/`;
    return Api.post(url, formData)
      .then(({ status, data }) => {
        if (status === 200) {
          const { message, message_type } = data;
          console.log(message_type, message);
          // message="Not Eligible"; message_type="warning"
          return [message, message_type];
        }
        return Promise.reject(
          new Error(data.message || 'Error while adding new annotation')
        );
      })
      .catch(({ status }) => {});
  }
}

export class ContactSiteApi {
  static sendMessage({
    trialId,
    siteId,
    widgetId,
    name,
    email,
    phone,
    dialingCode,
    message,
    captchaResponse,
    includeAnnotations,
    hasUserConsent,
    preferredLanguage,
    otherLanguage,
    country,
    language,
  }) {
    const url = `/message/direct/`;

    const headers = { 'X-Requested-With': 'XMLHttpRequest' };

    const data = new FormData();
    let user_country = null
    data.append('phonenumber', `+${dialingCode}${phone}`);
    data.append('firstname', name);
    data.append('lastname', '');
    data.append('email', email);
    data.append('comment', message);
    data.append('trial', trialId);
    data.append('site_id', siteId);
    data.append('widget_id', widgetId);
    data.append('volunteer_link', true);
    data.append('g-recaptcha-response', captchaResponse);
    data.append('preferred_language', preferredLanguage);
    data.append('other_language', otherLanguage);
    data.append('country_code', country);
    data.append('language_code', language);
    data.append('user_country', user_country);
    data.append('dial_country', dialingCode);

    includeAnnotations &&
      data.append('include_annotations', includeAnnotations);
    hasUserConsent && data.append('cookie_consent', hasUserConsent);

    return Api.post(url, data, { headers }).then(({ status, data }) => {
      if (status === 200) {
        const {
          existing_volunteer: existingVolunteer,
          reason,
          status: success,
          volunteer_link: volunteerLink,
          rv_ptid: rv_ptid,
          rv_site: rv_site,
        } = data;

        return success
          ? Promise.resolve({ existingVolunteer, volunteerLink, rv_ptid, rv_site })
          : Promise.reject(new Error(reason));
      }

      return Promise.reject(new Error('Error while contacting trial site'));
    });
  }
  static getPreferredLanguages(){
  const url = `/message/preferred_languages/`;
  const params = {};
  return Api.get(url, { params }).then(({ status, data }) => {
      if (status === 200) {
      const {
          status: success,
          preferred_languages:languages ,
        } = data;
        return languages;
      }
      return Promise.reject(new Error('Error while contacting trial site'));
    });
}
};
export class TrialDetailApi {
  static saveRating({ trialId, ratingScore }) {
    const url = `/clinicaltrial/ratings/${trialId}/`;
    const data = new FormData();
    data.append('stars', ratingScore);
    return Api.post(url, data).then(({ status, data }) => {
      if (status === 201) {
        return data;
      }
      console.log(data);

      return Promise.reject(new Error('Something went wrong'));
    });
  }
}
export default {
  AnnotationApi,
  ClientWorkspaceApi,
  EligibilityApi,
  ContactSiteApi,
  TrialDetailApi,
};
