import { fromEvent, merge } from 'rxjs';

/**
 *  Helper method to add multiple listeners to a dom element for using as observable.
 *
 * @param {HTMLElement} target - The element on which the events will be listened.
 * @param {...string} eventNames - Event types that are to be attached to target.
 * @returns Observable for events
 */
export function listen(target, ...eventNames) {
  const eventObservers$ = eventNames.map((type) => fromEvent(target, type));

  return merge(...eventObservers$);
}
