import bindSearchFilter from 'commons/utils/filter_list';
import { setQueryParams, getURLResource } from 'commons/utils/uri';

const LOADING_DELAY_MILLIS = 300;

const $browseContinentList = $('#browse-continent-list');
const $browseCountrySection = $('#browse_countries');
const $browseCountryList = $('#browse-country-list');
const $browseStateCitySection = $('#browse_cities');
const $browseStateCityList = $('#browse-state-city-list');

function setCountryLoading(visible = false) {
  visible
    ? $browseCountrySection.addClass('browse-list-loading')
    : $browseCountrySection.removeClass('browse-list-loading');
}

function setStateCityLoading(visible = false) {
  visible
    ? $browseStateCitySection.addClass('browse-list-loading')
    : $browseStateCitySection.removeClass('browse-list-loading');
}

function getTotalCount(text = '') {
  const countRegex = /^((\d|,)*)\s/;

  if (!text) return '';

  const [, count] = countRegex.exec(text.trim());
  return count;
}

function updateUrlParams({ continent, country }) {
  setQueryParams({ continent, country }, { action: 'replace' });
}

function setItemSelected({ $itemList, $selectedItem }) {
  const $prevSelectedItem = $itemList.find('.selected-item');
  const $selectedItemCount = $selectedItem.find('.total-count');
  const $prevSelectedItemCount = $prevSelectedItem.find('.total-count');

  const prevTotalCountText = $prevSelectedItemCount.text();
  const totalCountText = $selectedItemCount.text();

  const totalCount = this.getTotalCount(totalCountText);
  const prevTotalCount = this.getTotalCount(prevTotalCountText);

  $prevSelectedItemCount.html(`${prevTotalCount} &rsaquo;`);
  $selectedItemCount.html(`${totalCount} &raquo;`);

  $prevSelectedItem.removeClass('selected-item');
  $selectedItem.addClass('selected-item');
}

async function onContinentClick(e) {
  e.preventDefault();
  const $continentBtn = $(e.currentTarget);
  const continent = $continentBtn.data('continent');

  this.updateUrlParams({ continent });
  this.setItemSelected({
    $itemList: $browseContinentList,
    $selectedItem: $browseContinentList.find(`[data-continent="${continent}"]`),
  });

  this.setCountryLoading(true);
  this.setStateCityLoading(true);

  const { country, state_city } = await this.fetchLocationList();

  await new Promise((res) => setTimeout(res, LOADING_DELAY_MILLIS));

  $browseCountryList.html(country);
  $browseStateCityList.html(state_city);

  this.setCountryLoading(false);
  this.setStateCityLoading(false);
  bindSearchFilter();
}

async function onCountryClick(e) {
  try {
    e.preventDefault();
    const $countryBtn = $(e.currentTarget);
    const continent = $countryBtn.data('continent');
    const country = $countryBtn.data('country');

    this.updateUrlParams({ continent, country });
    this.setItemSelected({
      $itemList: $browseCountryList,
      $selectedItem: $browseCountryList.find(`[data-country="${country}"]`),
    });
    this.setStateCityLoading(true);

    const { state_city } = await this.fetchLocationList();

    await new Promise((res) => setTimeout(res, LOADING_DELAY_MILLIS));

    $browseStateCityList.html(state_city);
    this.setStateCityLoading(false);
    bindSearchFilter();
  } catch (error) {}
}

function fetchLocationList() {
  const url = getURLResource();
  if (this.xhr) {
    this.xhr.abort();
    this.xhr = null;
  }

  this.xhr = $.ajax({
    url,
  });

  return this.xhr.promise();
}

function bindEventListeners() {
  $browseContinentList.on('click', '.select-continent', this.onContinentClick);
  $browseCountryList.on('click', '.select-country', this.onCountryClick);
}

function init() {
  this.bindEventListeners();
}

function BrowseLocation() {
  const self = this;

  self.init = init.bind(self);
  self.bindEventListeners = bindEventListeners.bind(self);
  self.onContinentClick = onContinentClick.bind(self);
  self.onCountryClick = onCountryClick.bind(self);
  self.setCountryLoading = setCountryLoading.bind(self);
  self.setStateCityLoading = setStateCityLoading.bind(self);
  self.setItemSelected = setItemSelected.bind(self);
  self.getTotalCount = getTotalCount.bind(self);
  self.updateUrlParams = updateUrlParams.bind(self);
  self.fetchLocationList = fetchLocationList.bind(self);
}

export default new BrowseLocation();
