import getValue from 'lodash/get';

let xhr = null;

function requiredParam(param, label) {
  if (!param) {
    throw new TypeError(`'${label}' is required param for autocomplete!`);
  }
}

function callFn(fn, ...params) {
  if (typeof fn === 'function') {
    fn(...params);
  }
}

const searchQueryTerm = (url, setLoading) => (query, callback, jQElement) => {
  // Cancel previous request
  if (xhr) {
    xhr.abort();
    xhr = null;
  }

  callFn(setLoading, true);

  xhr = $.ajax({
    url,
    data: {
      term: query,
    },
  })
    .done((results) => {
      const data = results || [];
      const actualTerm = query;
      const currentTerm = jQElement.val();
      const isInputFocused = jQElement.is(':focus');

      callFn(setLoading, false);
      // Only show relevant results when focus is within input
      if (isInputFocused && currentTerm.includes(actualTerm)) {
        callback(data);
      }
    })
    .fail((error) => {
      callback([]);
    });
};

ko.bindingHandlers.autoComplete = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {
    const value = valueAccessor();
    const config = ko.unwrap(value);

    const url = getValue(config, 'url', null);
    const minLength = getValue(config, 'minLength', 3);
    const noResultsText = getValue(config, 'noResultsText', 'No results');
    const onItemSelect = getValue(config, 'onItemSelect', null);
    const formatResults = getValue(config, 'formatResults', null);
    const setLoading = getValue(config, 'onLoadingChange', null);

    requiredParam(url, 'url');

    var isEmbedSearchComponent = false
    var isMultiple = false
    if ($(element).attr("is_multiple")) {
      isEmbedSearchComponent = true
      if ($(element).attr("is_multiple") == "True") {
        isMultiple = true
      }
    }

    if (isEmbedSearchComponent == true){
      $(element).autoComplete({
        bootstrapVersion: '4',
        minLength,
        noResultsText,
        formatResult: function (item) {
          return {
            value: item.value,
            text: item.text,
            disabled: item.disabled,  
            html:[$('<g>').css("padding-left", item.disabled ? 0 : 10), ' ', item.text]     
          };
        },
        events: {
          search: searchQueryTerm(url, setLoading),
          searchPost: function (serverResponse, jQElement) {
            var serverResponseTreated = parseServerResponseForSearchComponent(serverResponse,isMultiple)          
            if (typeof formatResults === 'function') {            
              return formatResults(serverResponseTreated);
            }
            return serverResponseTreated;
          },
        },
      });
    }else{
      $(element).autoComplete({
        bootstrapVersion: '4',
        minLength,
        noResultsText,        
        events: {
          search: searchQueryTerm(url, setLoading),
          searchPost: function (serverResponse, jQElement) { 
            var serverResponseTreated = parseServerResponseForSearchComponent(serverResponse,isMultiple=false)     
            if (typeof formatResults === 'function') {            
              return formatResults(serverResponseTreated);
            }
            return serverResponseTreated;
          },
        },
      });
    }

    $('body').on('autocomplete.select', element, function (e) {
      e.preventDefault();
      const value = getValue(e, 'target.value', null);
      if (typeof onItemSelect === 'function') {
        onItemSelect(value);
      }
    });

    $('body').on('click', '.bootstrap-autocomplete.dropdown-menu a', function (
      e,
    ) {
      e.preventDefault();
    });
  },
};

function parseServerResponseForSearchComponent(serverResponse,isMultiple){

  var serverResponseTreated = []

  if (isMultiple == true){ 
    var categoryTitle = ""
    for (let i=0;i<serverResponse.length;i++){
      if (categoryTitle === ""){
        categoryTitle = serverResponse[i]['category']
        serverResponseTreated.push({
          "value": 0,
          "text": '<g style="color:grey;font-size:12px;letter-spacing: 2px;">'+categoryTitle.toUpperCase()+'</g>', 
          "disabled":true
        })
      }else{
        if (serverResponse[i]['category'] == categoryTitle){
          // Does Nothing
        }else{
          categoryTitle = serverResponse[i]['category']
          serverResponseTreated.push({
            "value": 0,
            "text": '<g style="color:grey;font-size:12px;letter-spacing: 2px;">'+categoryTitle.toUpperCase()+'</g>', 
            "disabled":true
          })
        }
      }
      serverResponseTreated.push({
        "value": serverResponse[i]['label'],
        "text": serverResponse[i]['label'],"disabled":false,        
      })
      categoryTitle = serverResponse[i]['category']
    }
  }else{
    for (let i=0;i<serverResponse.length;i++){
      serverResponseTreated.push({
        "value": serverResponse[i]['label'],
        "text": serverResponse[i]['label']
      })
    }
  }
  
return serverResponseTreated

}
