import _ from 'lodash';
import queryString from 'query-string';
import history from 'history/browser';
import { Observable } from 'rxjs';

export function uniqueValues(array) {
  return _.uniq(array);
}

export function uniqueValuesObjects(object) {
  //{k:[1,2,3,1,2],s:4}
  // =>{k:[1,2,3],s:[4]}
  let data = {};
  for (var key in object) {
    const value = object[key];
    if (Array.isArray(value)) {
      data[key] = uniqueValues(value);
    } else {
      data[key] = [value];
    }
  }
  return data;
}

export function locationQuery(decode = false) {
  let search = location.search;
  if (decode) {
    search = decodeURIComponent(search);
  }
  const parsed = queryString.parse(search);
  return uniqueValuesObjects(parsed);
}

export function changeRoute(mergedParams) {
  const stringified = queryString.stringify(mergedParams);
  history.push(history.location.pathname + '?' + stringified);
}

/**
 * Utility method for updating the current location in the browser location stack
 * @see {@link https://github.com/ReactTraining/history/blob/28c89f4091ae9e1b0001341ea60c629674e83627/docs/navigation.md}
 *
 * @param {string|object} url contains the full to be updated on current browser location
 * @param {push|pop|replace} [action=push] action used to update the location history stack
 */
export function updateLocationHistory(url, action = 'push') {
  history[action](url);
}

/**
 * Utility method for listening to browser location change.
 *
 * @returns {Observable} for subscribing to location changes.
 */
export function listenLocationChange() {
  return Observable.create(function (observer) {
    const unlisten = history.listen((location) => observer.next(location));

    return unlisten;
  });
}

export function navigateTo(url) {
  window.location.href = url;
}
