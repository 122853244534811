import $ from 'jquery';
import { createPopper } from '@popperjs/core';

function clickAwayListener(container, target) {
  function handleClickAway(e) {
    if (!container[0].contains(e.target)) {
      $(this).unbind('click touchend', handleClickAway);
      if(target[0].contains(e.target)){
        return
      }
      container.fadeOut();
      target.attr('aria-expanded', false);
    }
  }
  $('body').on('click touchend', handleClickAway); // Touch end event for iOS safari  <= 12, as click event not bubbled other then button/link
}
export function showPopover(e, popoverRef, hasClickAway) {
  e.preventDefault();
  const $clickedTarget = $(this);
  const isPopoverExpanded = $clickedTarget.attr('aria-expanded');
  const targetId = $clickedTarget.data().target;
  if (isPopoverExpanded === 'false') {
    $clickedTarget.attr('aria-expanded', true);
    $(targetId).fadeIn(function () {
      if (e.type === 'click' && hasClickAway) {
        const $popoverElement = $(this);
        clickAwayListener($popoverElement, $clickedTarget);
      }
    });
  }else {
    $(targetId).fadeOut();
    $clickedTarget.attr('aria-expanded', false);
  }
  popoverRef.forceUpdate();
}
//popover
const targetListeners = {}

//function to store event listeners on popover
function addTargetListener(id, clb, type='click') {
  if (targetListeners[id]) {
      targetListeners[id].push({type, clb})
  } else {
      targetListeners[id] = [clb]
  }
}
//function to remove existing event listeners on popover
function removeTargetListener(popperId, $target) {
  if (targetListeners[popperId]) {
      const listeners = targetListeners[popperId]
      listeners.forEach(({type, clb}) => $target.off(type, clb))
      delete targetListeners[popperId]
  }
}
export function bindPopovers(target = $('.popover-target')) {
  
  target.each(function () {
    const $target = $(this);
    const {
      target: popoverId,
      event = 'click',
      strategy = 'fixed',
      hasClickAway = true,
      placement = 'auto',
    } = $target.data();
    const $popoverTarget = $(popoverId)[0];
    if (!$popoverTarget) return;
    const popoverRef = createPopper($target[0], $popoverTarget, {
      placement,
      strategy,
    });
    if (event === 'click') {
      function onClick(e) {
        showPopover.call(this, e, popoverRef, hasClickAway);
      }
      removeTargetListener(popoverId, $target)
      addTargetListener(popoverId, onClick)
      $target.click(onClick);
    } else if (event === 'hover') {
      function onMouseEnter(e) {
        showPopover.call(this, e, popoverRef, false);
      }
      function onMouseLeave(e){
        $(popoverId).fadeOut();
        $(this).attr('aria-expanded', false);
      }
      removeTargetListener(popoverId, $target)
      addTargetListener(popoverId, onMouseEnter, 'mouseenter')
      addTargetListener(popoverId, onMouseLeave, 'mouseleave')
      $target.mouseenter(onMouseEnter);
      $target.mouseleave(onMouseLeave);
    }
  });
}



