import { fromEvent } from 'rxjs';
import { take, filter, debounceTime } from 'rxjs/operators';
import { functionalConsentChange$ } from 'commons/one_trust/consent';
import { getViewportDimensions } from 'commons/utils/dom';

const ANNOTATION_GUIDE_KEY = '@guide/annotations-guide';

const $annotationGuideMobile = $('#annotation-guide-mobile');

function isGuideShown() {
  const isGuideShown = window.localStorage.getItem(ANNOTATION_GUIDE_KEY);
  return JSON.parse(isGuideShown);
}

function setAnnotationGuide(shown = false) {
  window.localStorage.setItem(ANNOTATION_GUIDE_KEY, shown);
}

function hideAnnotationsGuide() {
  $annotationGuideMobile.addClass('d-none');
  $('body').removeClass('modal-open');
}

function showAnnotationsGuide() {
  $annotationGuideMobile.removeClass('d-none');
  $('body').addClass('modal-open');
}

function handleGuideClose() {
  this.setAnnotationGuide(true);
  this.hideAnnotationsGuide();
}

function handleModalClose(e) {
  const $target = e.target;
  const isContentClicked = !!$target.closest('.annotation-guide-content');
  !isContentClicked && this.handleGuideClose();
}

function bindAnnotationGuide() {
  const isGuideShown = this.isGuideShown();
  const isSharedAnnotations = $annotationGuideMobile.data('shared-annotations');
  const $closeGIFGuideMobile = $annotationGuideMobile.find('.close-guide');
  const { viewportWidth } = getViewportDimensions();
  const isDrawerFullWidth = viewportWidth < 992;

  if (
    $annotationGuideMobile.length &&
    !isGuideShown &&
    !isSharedAnnotations &&
    isDrawerFullWidth
  ) {
    $closeGIFGuideMobile.click(this.handleGuideClose);
    $annotationGuideMobile.click(this.handleModalClose);
    fromEvent(window, 'scroll')
      .pipe(
        filter((_) => window.scrollY),
        debounceTime(500),
        take(1),
      )
      .subscribe((e) => {
        this.showAnnotationsGuide();

        setTimeout(() => {
          $closeGIFGuideMobile.removeClass('invisible');
        }, 2000);
      });
  }
}

function handleConsentChange(enabled) {
  if (enabled) {
    this.bindAnnotationGuide();
  } else {
    this.hideAnnotationsGuide();
  }
}

function init() {}

function AnnotationGuide() {
  const self = this;

  self.init = init.bind(self);
  self.isGuideShown = isGuideShown.bind(self);
  self.setAnnotationGuide = setAnnotationGuide.bind(self);
  self.handleGuideClose = handleGuideClose.bind(self);
  self.bindAnnotationGuide = bindAnnotationGuide.bind(self);
  self.showAnnotationsGuide = showAnnotationsGuide.bind(self);
  self.hideAnnotationsGuide = hideAnnotationsGuide.bind(self);
  self.handleConsentChange = handleConsentChange.bind(self);
  self.handleModalClose = handleModalClose.bind(self);

  functionalConsentChange$.subscribe(self.handleConsentChange);
}

export default new AnnotationGuide();
