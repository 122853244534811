import getValue from 'lodash/get';
import { getGooglePlaceDetails } from 'commons/utils/geo';


function handleMedicalCondition(condition) {
  this.medicalCondition(condition);
  const widgetId = this.widgetId
  let params = {condition}
  if(widgetId){
     params.widget = widgetId
  }
  $.ajax({
      url: `/clinical-trials/listings/general_autocomplete/`,
      method:`post`,
      data: params
    })
}

function setConditionLoading(loading) {
  this.isConditionLoading(loading);
}

function handleLocationChange(autoComplete) {
  const place = autoComplete.getPlace();
  const placeDetails = getGooglePlaceDetails(place);

  if (placeDetails) {
    const {
      locationName,
      latitude,
      longitude,
      countryName: country,
    } = placeDetails;

    const studyLocation = {
      locationName,
      geoLat: latitude.toFixed(4),
      geoLng: longitude.toFixed(4),
      country,
    };

    this.studyLocation(studyLocation);
    this.studyLocationInput(locationName);
    this.hasLocationError(false);
  } else {
    this.studyLocation(null);
  }
}

function isValidLocation() {
  const studyLocation = this.studyLocation();
  const studyLocationInput = this.studyLocationInput();
  const selectedLocationName = getValue(studyLocation, 'locationName', null);

  return !studyLocationInput || selectedLocationName === studyLocationInput;
}

function handleLocationInputChange(locationInput) {
  const locationSelected = this.studyLocation();
  const locationName = getValue(locationSelected, 'locationName', null);

  if (locationName && locationName !== locationInput) {
    this.studyLocation(null);
  }

  if (!locationInput) {
    this.hasLocationError(false);
  }
}

function onSearchSubmit() {
  const medicalCondition = this.medicalCondition();
  const canSearchStudy = this.canSearchStudy();
  const isValidLocation = this.isValidLocation();

  this.hasLocationError(!isValidLocation);

  if (canSearchStudy) {
    return true;
  }

  if (!medicalCondition) {
    this.$medicalConditionRef.focus();
  }

  return false;
}

function FindStudyViewModel({widgetId=null}={}) {
  const self = this;
  self.widgetId = widgetId
  self.$medicalConditionRef = $('#search-medical-conditions');

  self.medicalCondition = ko.observable(null);
  self.studyLocation = ko.observable(null);
  self.studyLocationInput = ko.observable();
  self.isConditionLoading = ko.observable(false);
  self.hasLocationError = ko.observable(false);
  self.hasConditionFocus = ko.observable(false);

  self.canSearchStudy = ko.computed(function () {
    return !!this.medicalCondition() && this.isValidLocation();
  }, self);

  self.handleMedicalCondition = handleMedicalCondition.bind(self);
  self.handleLocationChange = handleLocationChange.bind(self);
  self.handleLocationInputChange = handleLocationInputChange.bind(self);
  self.setConditionLoading = setConditionLoading.bind(self);
  self.onSearchSubmit = onSearchSubmit.bind(self);
  self.isValidLocation = isValidLocation.bind(self);

  self.studyLocationInput.subscribe(self.handleLocationInputChange);
  
  if(!window.location.hash){
    self.hasConditionFocus(true)
  }
}

export default FindStudyViewModel;
