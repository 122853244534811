import { functionalConsentChange$ } from 'commons/one_trust/consent';

const WORKSPACE_GUIDE_CREATED_KEY = '@guide/workspace-created';

const $workspaceCreatedGuide = $('#workspace-guide-created');

function setLocalState(key, shown = false) {
  window.localStorage.setItem(key, shown);
}

function getLocalState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function hideWorkspaceCreatedGuide() {
  $workspaceCreatedGuide.addClass('d-none');
}

function showWorkspaceCreatedGuide() {
  $workspaceCreatedGuide.removeClass('d-none');
}

function onWorkspaceCreated() {
  const $closeGuideBtn = $workspaceCreatedGuide.find('.close-guide');
  const isGuideShown = this.getLocalState(WORKSPACE_GUIDE_CREATED_KEY);

  if (!isGuideShown) {
    this.showWorkspaceCreatedGuide();
    this.setLocalState(WORKSPACE_GUIDE_CREATED_KEY, true);

    $closeGuideBtn.click(this.hideWorkspaceCreatedGuide);
  }
}

function handleConsentChange(enabled) {
  if (!enabled) {
    this.hideWorkspaceCreatedGuide();
  }
}

function WorkspaceGuide() {
  const self = this;

  self.setLocalState = setLocalState.bind(self);
  self.getLocalState = getLocalState.bind(self);
  self.showWorkspaceCreatedGuide = showWorkspaceCreatedGuide.bind(self);
  self.hideWorkspaceCreatedGuide = hideWorkspaceCreatedGuide.bind(self);
  self.handleConsentChange = handleConsentChange.bind(self);
  self.onWorkspaceCreated = onWorkspaceCreated.bind(self);

  functionalConsentChange$.subscribe(self.handleConsentChange);
}

export default new WorkspaceGuide();
